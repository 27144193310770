import React from "react"
import Image from "../components/image"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-grid-system';

const portfolio = { "flow": { "title": "Flow Institut Freiburg", "type": "Website Design", "url": "http:\/\/flow-institut-freiburg.de" }, "jugendfilmpreis": { "title": "Jugendfilmpreis", "type": "Website Design", "url": "http:\/\/jugendfilmpreis.de" }, "iff": { "title": "Indisches Filmfestival", "type": "Website Design", "url": "http:\/\/indisches-filmfestival.de" }, "schacht": { "title": "Schacht Immobilien", "type": "Website Design", "url": "http:\/\/schacht-immo.de" }, "geis": { "title": "Musikwoche Geislingen", "type": "Website Design", "url": "http:\/\/musikwoche-geislingen.de" }, "setmanager": { "title": "SetManager", "type": "Web application", "url": "http:\/\/setmanager.de" }, "djaka": { "title": "DJ Akademie Berlin", "type": "Relaunch", "url": "http:\/\/dj-akademie-berlin.de" }, "hpae": { "title": "Natuerheilpraxis Englerplatz", "type": "Website Design", "url": "http:\/\/naturheilpraxis-am-englerplatz.de" }, "jfc": { "title": "JugendFilmCamp Arendsee", "type": "Registration system", "url": "http:\/\/jugendfilmcamp.de\/Anmeldeformular" }, "fhps": { "title": "Freie Heilpraktikerschule Freiburg", "type": "Website Design", "url": "http:\/\/freie-heilpraktikerschule.de" }, "tisw": { "title": "Theater im Steinbruch", "type": "Website Design", "url": "http:\/\/theater-im-steinbruch.de" }, "ghse": { "title": "GHSE Schulen", "type": "Website Design", "url": "http:\/\/intranet.ghse.de" }, "redrain": { "title": "RedRain Cinema", "type": "Website Design", "url": "http:\/\/redraincine.com" }, "enok": { "title": "Enno Kastens", "type": "Website Design", "url": "http:\/\/ennokastens.de" }, "dorn": { "title": "Dorn-Therapie", "type": "Website Design", "url": "http:\/\/dorn-praxis.de" }, "rfpm": { "title": "Raum f\u00fcr Pr\u00e4senz", "type": "Website Design", "url": "http:\/\/praesenz.net" }, "gwis": { "title": "Heil-K\u00fcnste", "type": "Website Design", "url": "http:\/\/heil-kuenste.de" } }
console.log(portfolio)

class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state={h1:"",p:"",id:"",overlayVisible:false}
    }
    render() {
        return (
    <Layout container={false} isDark={true}>
        <SEO title="Stories | Projekte, die wir bisher begleiten durften." />

            <div className="container-fluid hero-full hero-big text-center" id="stories">
                <div>
                    <h1 class="text-white">Stories von Kund:innen</h1>
                    <p className="hidden-sm  text-white mt-2"> Bitte nach unten scrollen.</p>
                </div>
            </div>

            <div className={(this.state.overlayVisible ? "blured" : "" )+ " box-overlay"} id={this.state.id}>
                <div>
                        <div className="img"></div>
                        <div className="textbox">
                            <h1>{this.state.h1}</h1>
                            <p className="text-gray">{this.state.p}</p>
                            <a onClick={()=>{
                                this.setState({overlayVisible:false})
                            }}>Schliessen</a>
                        </div>
                </div>
            </div>
        
            <div className="container-fluid hero container-col-2" id="profour">
                    <div className="img"></div>
                    <div className="textbox">
                        <h1>ProFour Insects</h1>
                        <p className="text-gray">Das in Arendsee ansässige Unternehmen ProFour Insects setzt bei der Verwaltung der Brutkästen auf eine von uns speziell entwickelte Softwarelösung.</p>
                        <p className="text-gray text-small">* Details unter profour.de</p>
                        <a onClick={()=>{
                            this.setState({id:"profour",p:`
                                Sancho Müller begann 2016 mit den ersten Versuchen, in der Garage Larven und Puppen aufzuziehen. Er kennt das Potential der kleinen Insekten.
                                Mittlerweile besitzt er ein Firmengelände mit vier Hallen. Die gesamte Aufzucht wird mit unserer Software "Incubators" verwaltet und bietet einen 
                                Überblick in Echtzeit über sämtliche Tiere.
                                `, h1:"Magische Larven",overlayVisible:true})
                        }} className="text-lime">Mehr erfahren ›</a>

                    </div>
            </div>


        
            <div className="container-fluid hero container-col-2" id="influenzar">
                    <div className="img" ></div>
                    <div className="textbox">
                        <h1>Influen<span style={{opacity:".5"}}>za</span>r</h1>
                        <p className="text-gray">Über 100 jugendliche Filmschaffende haben sich kreativ durch den Lockdown gedreht,
                        realisiert mit einer von uns entwickelten Lern- und Austauschplattform.</p>
                        <p className="text-gray text-small">* Details unter jugendfilmcamp.online</p>
                        <a onClick={()=>{
                            this.setState({id:"influenzar",p:`
                               Frühjahr 2020 – und nichts ist wie vorher. Die ganze Welt ist im Krisenmodus,  wir legen mit unseren Mitteln Zeugnis ab von diesem einmaligen Moment unseres Lebens. So entsteht ein filmisches Zeitdokument mit Unterstützung der Friedrich-Ebert-Stiftung.
                               Eine Plattform zum Austausch und zur Organisation von mehreren Filmteams, die im Wochenrhythmus Kurzfilme zu aktuellen Themen produzieren.
                                `, h1:"Filme in Quarantäne drehen",overlayVisible:true})
                        }} className="text-lime">Mehr erfahren ›</a>

                    </div>
            </div>


 
            <div className="container-fluid hero container-col-2" id="fhps">
                    <div className="img" ></div>
                    <div className="textbox">
                        <h1>Unterrichts<wbr/>ausfall</h1>
                        <p className="text-gray">Erfahren Sie, wie die Freie Heilpraktikerschule Freiburg ihren Unterrichtsplan perfekt einhält.</p>
                        <p className="text-gray text-small">* Details unter freie-heilpraktikerschule.de</p>
                        <a onClick={()=>{
                            this.setState({id:"fhps",p:`
                                Aufgrund der Zunahme der angebotenen Kurse, wird die Organisation der zuständigen Dozierenden immer komplexer.
                                Seit mehreren Jahren sorgen wir erfolgreich mit unserer Software für mehr Durchblick.
                                Der Algorhythmus gruppiert intelligent Unterrichtszeiten der Dozierenden und holt Bestätigungen ein. 
                                Ist ein Unterrichtsausfall absehbar, informiert er das Büro der Schule rechtzeitig.
                                `, h1:"Stundenplan Supervisor",overlayVisible:true})
                        }} className="text-lime">Mehr erfahren ›</a>
                    </div>
            </div>

            <div className="container-fluid hero" id="vplan">

                        <picture>
                            <source srcSet={require("../images/stories/vplan.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/stories/vplan.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../images/stories/vplan.jpg")}/>
                        </picture>



                <div className="textbox">
                    <h1>Stundenplaner</h1>
                    <p className="text-gray">Lehrer zum Anfassen - So unser Motto beim 1. Platz bei Jugend forscht 2018 regional. Unsere Software ist seither durchgängig im täglichen Einsatz.</p>
                    <p className="text-gray text-small">* Details unter ghse.de/downloads/projekte-software/</p>
                    <a  onClick={()=>{
                        this.setState({id:"vplan-box",p:`
                          Diese Software vollendet die Vorstellung von Vertretungsplänen in der Zukunft: Welche Klasse hat in welchem Raum Unterricht? Welcher Lehrer ist anwesend? Welcher Raum ist frei? – Mit zwei, drei Fingertipps auf dem Touchscreen gelangt der Benutzer zu jeglicher Information.
                        `, h1:"Interaktiver Vertretungsplan",overlayVisible:true})
                    }} >Mehr erfahren ›</a>
                </div>
            </div>

            <div className="container-fluid hero container-col-2" id="fsbwo">
                    <div className="img" ></div>
                    <div className="textbox">
                        <h1>Online Kino</h1>
                        <p className="text-gray">Wenn Festivals plötzlich schwierig werden. So reagierte auch das Indische Filmfestival kurzerhand pragmatisch und ließ von uns ein Online Festival entwickeln. Kurze Zeit später wurde das System auch von der Filmschau Baden Württemberg und dem Jugendfilmpreis eingesetzt.</p>
                        <p className="text-gray text-small">* Details unter indisches-filmfestival, filmschaubw.de, jugendfilmpreis.de</p>
                        <a className="text-lime" onClick={()=>{
                            this.setState({id:"fsbwo",p:`
                            Zum ersten Mal wird die Filmschau Baden-Württemberg im Onlineformat zu sehen sein, das Programm bleibt gleich. Dieses Jahr könnt ihr also ganz entspannt das gesamte Programm von zu Hause mitverfolgen und dabei sein. 
                            Unser Streaming Portal löst dabei das Problem begrenzter Platzkarten von Filmverleihen. Dabei bietet es die Möglichkeit
                            einer hybriden Vorstellung. Dadurch kann eine festgelegte Besucherzahl die Vorstellung persönlich wahrnehmen. 
                            `, h1:"Hybrid Festival",overlayVisible:true})
                        }} >Mehr erfahren ›</a>
                    </div>
            </div>


            <div className="container-fluid hero container-col-2" id="tis">
                    <div className="img" ></div>
                    <div className="textbox">
                        <h1>Bonkasse</h1>
                        <p className="text-gray">Das Freilicht Theater in Emmendingen setzt auf ein speziell entwickeltes Bonkassensystem, das auf RFC Grundlage extrem schnelle kontaktlose Bestellungen ermöglicht.</p>
                        <p className="text-gray text-small">* Details unter theater-im-steinbruch.de</p>
                        <a className="text-lime" onClick={()=>{
                            this.setState({id:"tis",p:`
                            Neben einem neuen Webauftritt durften wir 2017 auch ein Bonkassensystem zur Getränke- und Speisenbestellung in Betrieb nehmen. 
                            Der Fokus des neuen Systems lag dabei auf Schnelligkeit & Intuitivität.
                            Mitarbeitende können dabei Zahlungen mit ihrem persönlichen RFC Chip verknüpfen und sich eine Bestellübersicht anzeigen lassen.
                            `, h1:"Abendtheater mit Chips",overlayVisible:true})
                        }} >Mehr erfahren ›</a>

                    </div>
            </div>
 


            <div className="container-fluid hero container-col-2" id="setmanager">
                    <div className="img" ></div>
                    <div className="textbox">
                        <h1>Produktions<wbr/>assistenz</h1>
                        <p className="text-gray">SetManager deckt alle Departments einer professionellen Filmproduktion ab. Von automatischer Dispositionsgenerierung bis zur Auslieferung per Smart Home.</p>
                        <p className="text-gray text-small">* Details unter apps.softatomos.com/setmanager</p>
                        <a className="text-lime" onClick={()=>{
                            this.setState({id:"setmanager",p:`
                            Bei Projekten von Imagefilm bis Independent Production hilft SetManager bei allen Phasen des Filmprojektes, Aufgaben besser zu erledigen, Fehlern vorzubeugen und Dokumente zu generieren.
                            `, h1:"Die smarte Aufnahmeleitung",overlayVisible:true})
                        }} >Mehr erfahren ›</a>

                    </div>
            </div>




            <div className="container-fluid hero container-row-2" id="s2">

                        <picture>
                            <source srcSet={require("../images/stories/s2.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/stories/s2.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../images/stories/s2.jpg")}/>
                        </picture>



                    <div className="textbox">
                        <h1>Lagersoftware</h1>
                        <p className="text-gray">Unsere Lagersoftware S2 verfügt über komplexe Funktionen zur vollständig automatisierten Lagerverwaltung. </p>
                        <p className="text-gray text-small">* Details unter ziegenhagel.com/stories/s2</p>
                    </div>
            </div>



            <div className="container-fluid hero container-row-2" id="overtime">

                        <picture>
                            <source srcSet={require("../images/stories/overtime.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/stories/overtime.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../images/stories/overtime.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1>Abrechnungs KI</h1>
                        <p className="text-gray">Auch wir lassen uns von unserer Software unterstützen. Wir präsentieren Overtime, unsere vollautomatische Abrechnungs Software.</p>
                        <p className="text-gray text-small">* Details unter ziegenhagel.com/stories/overtime</p>
                    </div>
            </div>



            <div className="container-fluid hero container-row-2" id="jfc">

                        <picture>
                            <source srcSet={require("../images/stories/jfc.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/stories/jfc.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../images/stories/jfc.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1>Teilnahme-Management</h1>
                        <p className="text-gray">Das Jugendfilmcamp Arendsee bietet Jährlich rund 600 jugendlichen Menschen einen Einblick in die Film- und Medienwelt. </p>
                        <p className="text-gray text-small">* Details unter jugendfilmcamp.de</p>
                    </div>
            </div>




            <div className="container-fluid hero container-row-2" id="fif">

                        <picture>
                            <source srcSet={require("../images/stories/fif.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/stories/fif.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../images/stories/fif.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1>Digitalisiert</h1>
                        <p className="text-gray">Cornelia Grix hat durch COVID-19 ihr Flow Institut zu einer Videoplattform mit Shopsystem erweitert.</p>
                        <p className="text-gray text-small">* Details unter flow-institut-freiburg.de</p>
                    </div>
            </div>


            <div className="container-fluid hero" id="coffee">
                <div className="container">

                        <picture>
                            <source srcSet={require("../images/landing/coffee_1500.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../images/landing/coffee_1500.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../images/landing/coffee_1500.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1 className="text-white">Auf n Kaffee?</h1>
                        <p className="text-white">Bald könnte auch Ihre Story hier landen!  </p>
                        <Link to="/mehr-erfahren" className="text-white btn btn-lime-outline">Jetzt anfragen ›</Link>
                    </div>
                </div>
            </div>



           </Layout>)
    }
}

export default Page
